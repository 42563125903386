import React from 'react'
import thankuimage from './assets/images/thankyou-image.svg'
import Helmet from 'react-helmet';
const ThankYouPageLayout =()=>{
return(
    <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
           <Helmet defer={false}>
                <title>Thank you | JDSolutions</title>
            </Helmet>
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell thku_wrap">
        <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
        <div className="row-fluid ">
            <div className="thankyou-section">
                <div className="span12 widget-span widget-type-cell ">
                    <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-rich_text thnku-content">
                                <div className="cell-wrapper layout-widget-wrapper">
                                <div className="en-text">
                                <h1>Thank You <span>For contacting us!</span></h1>
                                </div>
                                    <img src={thankuimage} alt="thanku image"/>
                                <div className="en-text">
                                <p>Someone from our team will contact you within the next 24 hours</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
        </div>
    </div>
)
}
export default ThankYouPageLayout;