import React, { useState } from 'react'

const Footer =()=>{
    const d = new Date().getFullYear()
    const [year,setYear] = useState(`${d}`)
    return(
<div className="footer-container-wrapper">
<div className="footer-container container-fluid">
<div className="row-fluid-wrapper row-depth-1 row-number-1 ">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-global_group ">
<div>
<div className="row-fluid-wrapper row-depth-1 row-number-1">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell footer-section">

<div className="row-fluid-wrapper row-depth-1 row-number-2">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell wrapper">

<div className="row-fluid-wrapper row-depth-1 row-number-3">
<div className="row-fluid ">
<div className="span6 widget-span widget-type-raw_jinja align-left footer-left">
    <p>JDSolutions {year}</p>
</div>

<div className="span6 widget-span widget-type-rich_text align-right footer-right">
<div className="cell-wrapper layout-widget-wrapper">
<p>All rights reserved</p>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
    )
}
export default Footer