import React,{useState} from 'react'
import Layout from '../layouts'
import ThankYouPageLayout from '../components/thankyou'
import Footer from '../components/footer'
const ThankYouPage =()=>{
    
const [page, setPage] = useState('blog hs-blog-listing formal thankupage')
    return(
        <Layout page={page}>
        <ThankYouPageLayout/>
        <Footer/>
        <div
        dangerouslySetInnerHTML={{
            __html: `<script>
            window.cg_conversion = true;
        </script>
        `,
        }}
        />
        </Layout>
    )
}
export default ThankYouPage